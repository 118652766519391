import { ShadowType, BorderRadiusType, BorderType, ColorType } from './types'
import { colors } from './colors'

export const getShadowCss = (type: ShadowType) => {
  if (type === 1) { return '0 0 2px 0 rgba(0,0,0,0.16),0 2px 2px 0 rgba(0,0,0,0.16),0 1px 3px 0 rgba(0,0,0,0.16)' } else if (type === 2) { return '0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0, 0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.16)' } else if (type === 3) { return '0 3px 3px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0, 0, 0.18), 0 1px 8px 0 rgba(0, 0, 0, 0.2)' } else if (type === 4) { return '0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 10px 0 rgba(0, 0, 0, 0.18)' } else if (type === 5) { return '0 5px 10px 0 rgba(0, 0, 0, 0.14), 0 2px 6px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.18);' } else if (type === 6) { return '0 6px 10px 0 rgba(0, 0, 0, 0.16), 0 1px 12px 0 rgba(0, 0, 0, 0.18), 0 3px 5px 0 rgba(0, 0, 0, 0.2)' } else { return 'none' }
}

export const getBorderCss = (type: BorderType) => {
  if (type === 'thin') {
    return '1px'
  } else if (type === 'regular') {
    return '2px'
  } else {
    return '0'
  }
}

export const getBorderRadiusCss = (type: BorderRadiusType) => {
  if (type === 'light') {
    return '0'
  } else if (type === 'medium') {
    return '4px'
  } else {
    return '8px'
  }
}

export const getBorderColorCss = (type: ColorType) => {
  if (type === 'primary') {
    return colors.primary.base
  } else if (type === 'secondary') {
    return colors.secondary.base
  } else if (type === 'tertiary') {
    return colors.tertiary.base
  } else if (type === 'warning') {
    return colors.warning.base
  } else if (type === 'altw') {
    return colors.altw.base
  } else if (type === 'error') {
    return colors.error.base
  } else {
    return 'none'
  }
}

export const hexToRgb = (hex: any) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}
