import * as React from 'react';
import { logoProps } from './_types';
import IconBase from './IconBase';

const Exit = ( props:logoProps ) => {
  return (
    <IconBase {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M29 2V24.7624L18.6131 30V24.7624H8.19347V17.7459H9.92462V22.9835H18.6131V7.23765L25.5377 3.74588H9.92462V10.7294H8.19347V2H29ZM6.22613 9.01647V12.5082H14.8819V16H6.19347V19.4918L1 14.2541L6.22613 9.01647Z" />
    </IconBase>
  )
}

export default Exit;