import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../common/colors'

interface DrawerItemProps {
  label: string
  icon: React.ReactNode
  active?: boolean
  className?: string
  onClick?: () => void
}

export const BaseDrawerItem = (props: DrawerItemProps) => {
  return (
    <div
      className={'drawer-item ' + props.className}
      role='button'
      aria-disabled='false'
      onClick={props.onClick}
    >
      <div className='drawer-item-icon'>{props.icon}</div>
      <div className='drawer-item-label'>
        <span>{props.label}</span>
      </div>
    </div>
  )
}

export const DrawerItem = styled(BaseDrawerItem)`
  :hover {
    background-color: ${(props) =>
    props.active ? colors.grayscale.white : 'rgba(0, 0, 0, 0.04)'};
  }

  background-color: ${(props) =>
    props.active ? colors.grayscale.white : 'inherit'};

  .drawer-item-label span {
    display: block;
    width: 150px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    text-decoration:none;
    color: ${(props) =>
    props.active ? colors.primary.base : colors.grayscale.white};
    font-weight: ${(props) =>
    props.active ? 700 : 'normal'};
  }

  svg {
    fill: ${(props) =>
    props.active ? colors.primary.base : colors.grayscale.white};
  }
`

interface DrawerItemSeparatorProps {
  label?: string
}

export const BaseDrawerItemSeparator = (props: DrawerItemSeparatorProps) => {
  return (
    <div
    >
      <hr className='separator' />
      {props.label && <span className='separator-label'>{props.label}</span>}
    </div>
  )
}

export const DrawerItemSeparator = styled(BaseDrawerItemSeparator)`
`
