import * as React from 'react'
import styled from 'styled-components'
import { tableRowProps, TableItemWithChildrensProps } from '../types'
import TableRow from './TableRow'
import TableCell from './TableCell'

const TableHeadItem = styled.tbody<TableItemWithChildrensProps>`
  @media only screen and (max-width: 61em) {
    display: block;
  }
`

const TableBody = (props: TableItemWithChildrensProps) => {
  return (
    <TableHeadItem>
      {props.children}
    </TableHeadItem>
  )
}

const TableContent = (props: tableRowProps) => {
  return (
    <TableBody>
      {props.data &&
      props.data.map((item: any[], index: number) => {
        return (
          <TableRow key={index}>
            {item.map((row: any, index_row: number) => {
              return (
                <TableCell key={index_row}>{row}</TableCell>
              )
            })}
          </TableRow>
        )
      })
      }
    </TableBody>
  )
}

export default TableContent
export { TableBody }
