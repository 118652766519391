import * as React from 'react';
import { CheckboxGroupProps, CheckboxProps } from './types';
import styled from 'styled-components';

const BaseCheckboxGroup = (props: CheckboxGroupProps) => {

    const childrenWithProps = React.Children.map(props.children, (child: any) => {
        return React.cloneElement(child as unknown as React.ReactElement<CheckboxProps>, { 
            name: props.name, 
            onChange: props.onChange,
            checked: props.value.indexOf(child.props.value) !== -1
        });
    });

    return (
        <div className={props.className}>
            {props.label && <p>{props.label}</p>}
            {
                childrenWithProps
            }
        {props.error && props.errorText && <span className="error">{props.errorText}</span>}
        </div>
    );
}

export const CheckboxGroup = styled(BaseCheckboxGroup)`
    label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: inherit;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    span.checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border-radius: 4px;
        background-color: transparent;
        border: 3px solid #979797;
        box-sizing: border-box;
    }

    span.error {
        font-size: 0.75rem;
        color: #f36666;
        margin-bottom: 10px;
        display: block;
    }

    label:hover input~span.checkmark {
        background-color: #DFDFDF;
        border-color: '#005DBA'
    }

    label:hover input:checked~span.checkmark {
        background-color: #005DBA;
    }

    input:checked~span.checkmark {
        background-color: ${props => !props.error ? '#005DBA;' : '#f36666'};
        border-color: ${props => !props.error ? '#005DBA;' : '#f36666'};
        box-sizing: border-box;
    }

    input:disabled~span.checkmark, label:hover input:disabled~span.checkmark {
        background-color: #979797;
        
    }

    span.checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        box-sizing: border-box;
    }

    input:checked~span.checkmark:after {
        display: block;
        box-sizing: border-box;
    }
`
