import * as React from 'react';
import { CheckboxProps } from './types';

export const Checkbox = (props: CheckboxProps) => {
    return (
        <label>
            {props.label && props.label}
            <input type="checkbox" {...props} checked={props.checked} />
            <span className="checkmark"></span>
        </label>

    );
}
