import * as React from 'react'
import styled from 'styled-components'
import { TableItemWithChildrensProps } from '../types'
import { colors } from '../../colors'

const TableRowItem = styled.tr`
  @media only screen and (max-width: 61em) {
    display: block;
    border-bottom: 2px solid ${colors.grayscale.gray10};
  }
`

export const TableRow = (props: TableItemWithChildrensProps) => {
  return (
    <TableRowItem>
      {props.children}
    </TableRowItem>
  )
}

export default TableRow
