import * as React from 'react'
import styled from 'styled-components'
import Menu from '../common/icons/Menu'
import ChevronLeft from '../common/icons/ChevronLeft'

interface DrawerProps {
  open: boolean
  children: React.ReactNode
  className?: string
  iconColor?: string
  onOpen?: () => void
  onClose?: () => void
}

export const BaseDrawer = (props: DrawerProps) => {
  const switchOpen = () => {
    if (props.open) {
      if (props.onClose) {
        props.onClose()
      }
    } else {
      if (props.onOpen) {
        props.onOpen()
      }
    }
  }

  return (
    <div className={props.className}>
      <div className='menu-responsive'>
        <div className='item' onClick={() => switchOpen()}>
          <Menu size={24} />
        </div>
      </div>

      <nav onMouseEnter={() => { if (props.onOpen) props.onOpen() }}
        onMouseLeave={() => { if (props.onClose) props.onClose() }}>
        <ul className='top'>
          <div className='menu-content'>
            <div className='drawer-button'>
              <div className='item' onClick={() => switchOpen()}>
                {props.open ? <ChevronLeft size={24} /> : <Menu size={24} />}
              </div>
            </div>
          </div>
        </ul>
        <div
          className={'menu-inner ' + (props.onOpen ? 'opened' : 'closed')}
        >
          {props.children}
        </div>

      </nav>
    </div>

  )
}

export const Drawer = styled(BaseDrawer)`
  overflow-x: hidden;

  nav {
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: absolute;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    background-color: #0073e8;
    -webkit-overflow-scrolling: touch;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .menu-responsive {
    display: none;
  }

  .separator-label {
    display: ${(props) => (props.open ? 'block' : 'none')} !important;
    color: rgba(255,255,255, 0.9);
    padding-top: 5px;
    font-size: 14px;
    padding-bottom: 10px;
  }

  @media only screen and (min-width : 320px) and (max-width : 480px) {
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }


    nav {
      width: ${(props) => (props.open ? '300px' : '0')} !important;
    }
  }


  @media only screen and (min-width : 321px) {
    width: 36px;
    float: left;


    nav {
      width: ${(props) => (props.open ? '300px' : '0')!};
    }
  }


  @media only screen and (max-width : 320px) {
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }


    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width : 768px) and (max-width : 1024px) {

    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }


  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }


  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {
    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }

  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }

  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }

  @media only screen  and (min-width : 1224px) {
    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }


  @media only screen  and (min-width : 1824px) {
    nav {
      width: ${(props) => (props.open ? '300px' : '60px')};
    }
  }


  @media only screen and (min-width : 320px) and (max-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width : 320px) and (max-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }


    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }


  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  @media only screen and (min-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 36px;
    float: left;

    .menu-responsive {
      display: block;
    }

    nav {
      width: ${(props) => (props.open ? '300px' : '0')};
    }
  }

  ul.top {
    padding: 0;
    list-style: none;
    position: relative;
    margin: 0;
    border-bottom: 1px solid rgba(0,0,0,0.15);
  }

  .item {
    color: #ffffff;
    display: inline-flex;
    min-width: 42px;
    flex-shrink: 0;
    margin: 6px 0;
    padding: 2px 0 2px 5px;
  }

  .item svg {
    fill: #fff;
  }

  .menu-responsive .item svg {
    fill: ${(props) => (props.iconColor ? props.iconColor : '#0073e8')};
  }

  .menu-content {
    padding: 0 12px;
  }

  .drawer-button,
  .drawer-item {
    margin: 0;
    padding: 15px 0 12px 0px;
    border-radius: 4px !important;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: inherit;
    border: 0;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }


  .menu-content {
    padding: 0 12px;
  }

  .menu-inner {
    margin: 0 10px;
  }

  .drawer-item {
    margin: 12px 0;
    padding: 7px 0 7px 9px;
    border-radius: 4px !important;
  }

  .drawer-item-icon {
    display: inline-flex;
    min-width: 46px;
    flex-shrink: 0;
  }

  .drawer-item-label {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .drawer-item-label span {
    font-size: 0.875rem;
    text-decoration: none;
  }

  hr.separator {
    border: none;
    height: 1px;
    margin: 0;
    margin: 0 0 0 10px
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
  }
`
