import * as React from 'react'
import styled from 'styled-components'
import { ButtonProps } from './types'
import { colors } from '../colors'
import preloader from '../assets/preloader.svg'

export const BaseButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  if (props.isloading) {
    return <button {...props} disabled><img src={preloader} alt='Cargando' /></button>
  } else {
    return <button {...props}>{props.children}</button>
  }
}

export const Button = styled(BaseButton)`
    font-family:'Nunito Sans', sans-serif;
    border:none;
    background:none;
    font-weight: 800;
    font-size: ${props => getFontSize(props.size)};
    border: 0;
    border-radius: 4px;
    padding: ${props => getPadding(props.size)};
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-transition: all 200ms cubic-bezier(0.08, 0.8, 0.565, 1);
    -webkit-transition: all 200ms cubic-bezier(0.08, 0.8, 0.565, 1.01);
    -moz-transition: all 200ms cubic-bezier(0.08, 0.8, 0.565, 1.01);
    -o-transition: all 200ms cubic-bezier(0.08, 0.8, 0.565, 1.01);
    transition: all 200ms cubic-bezier(0.08, 0.8, 0.565, 1.01);

    svg {
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }
`

export const PrimaryButton = styled(Button)`
    background-color:${props => props.disabled ? '#DADADA' : colors.primary.base};
    color:${props => props.disabled ? '#ADADAD' : colors.grayscale.white};

    svg {
        fill: ${colors.grayscale.white};
        stroke: ${colors.grayscale.white};
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }

    &:hover {
        background-color:${props => props.disabled ? '#DADADA' : colors.primary.soft};
    }`

export const SecondaryButton = styled(Button)`
    background-color: ${props => props.disabled ? '#DADADA' : colors.secondary.base};
    color:${props => props.disabled ? '#ADADAD' : colors.grayscale.white};

    svg {
        fill: ${colors.grayscale.white};
        stroke: ${colors.grayscale.white};
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }

    &:hover {
        background-color:${props => props.disabled ? '#DADADA' : colors.secondary.soft};
    }
    `

export const TertiaryButton = styled(Button)`
    background-color:${props => props.disabled ? '#DADADA' : colors.tertiary.base};
    color:${props => props.disabled ? '#ADADAD' : colors.grayscale.white};

    svg {
        fill: ${colors.grayscale.white};
        stroke: ${colors.grayscale.white};
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }


    &:hover {
        background-color:${props => props.disabled ? '#DADADA' : colors.tertiary.soft};
    }
    `

export const WarningButton = styled(Button)`
    background-color:${props => props.disabled ? '#DADADA' : colors.warning.base};
    color:${props => props.disabled ? '#ADADAD' : colors.grayscale.gray100};

    svg {
        fill: ${colors.grayscale.gray100};
        stroke: ${colors.grayscale.gray100};
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }


    &:hover {
        background-color:${props => props.disabled ? '#DADADA' : colors.warning.soft};
    }
    `

export const WarningAltButton = styled(Button)`
    background-color:${props => props.disabled ? '#DADADA' : colors.altw.base};
    color:${props => props.disabled ? '#ADADAD' : colors.grayscale.white};

    svg {
        fill: ${colors.grayscale.white};
        stroke: ${colors.grayscale.white};
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }

    &:hover {
        background-color:${props => props.disabled ? '#DADADA' : colors.altw.soft};
    }
    `

export const ErrorButton = styled(Button)`
    background-color:${props => props.disabled ? '#DADADA' : colors.error.base};
    color:${props => props.disabled ? '#ADADAD' : colors.grayscale.white};

    svg {
        fill: ${colors.grayscale.white};
        stroke: ${colors.grayscale.white};
        width: ${props => getIconSize(props.size)};
        height:  ${props => getIconSize(props.size)};
        margin: 0 5px;
    }


    &:hover {
        background-color:${props => props.disabled ? '#DADADA' : colors.error.soft};
    }
    `

const getFontSize = (size: string | undefined) => {
  if (size) {
    if (size === 'sm') {
      return '0.75rem'
    } else if (size === 'lg') {
      return '1.375rem'
    } else {
      return '1rem'
    }
  } else {
    return '1rem'
  }
}

const getIconSize = (size: string | undefined) => {
  if (size) {
    if (size === 'sm') {
      return '12px'
    } else if (size === 'lg') {
      return '32px'
    } else {
      return '16px'
    }
  } else {
    return '16px'
  }
}

const getPadding = (size: string | undefined) => {
  if (size) {
    if (size === 'sm') {
      return '7px'
    } else if (size === 'lg') {
      return '11px 60px'
    } else {
      return '11px 29px'
    }
  } else {
    return '11px 29px'
  }
}
