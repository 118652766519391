import React from 'react'
import styled from 'styled-components'

import { SwitchProps } from './types'

const BaseSwitch: React.FC<SwitchProps> = (props) => {
  const { checked = false, onChange, id, inputRef, color, disabled } = props
  return (
    <div className={props.className}>
      <label className={`toggle ${color} ${checked ? 'active' : ''}`}>
        <div className='circle'>
          <input
            disabled={disabled}
            data-testid='test-switch'
            type='checkbox'
            checked={checked}
            onChange={onChange}
            id={id}
            ref={inputRef}
          />
        </div>
      </label>
      {props.label && <p>{props.label}</p>}
    </div>
  )
}

export const Switch = styled(BaseSwitch)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	box-sizing: inherit;

	p {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}

	input {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	.toggle {
		position: relative;
		content: "";
		background-color: #9f9f9f;
		width: 34px;
		height: 14px;
		border-radius: 40px;
		margin-right: 9px;
		display: flex;
	}
	.toggle.active {
		background-color: #9cb9eb;
	}

	.toggle.active .circle {
		background-color: #3055bc;
		left: 14.2px;
	}

	.toggle .circle {
		width: 20px;
		height: 20px;
		content: "";
		background-color: #bdbdbd;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		transition: 0.5s;
		top: -3px;
		left: 0;
	}

	/* Secondary toggle */
	.toggle.secondary.active {
		background-color: #9f9f9f;
	}

	.toggle.secondary .circle {
		background-color: #fafafa;
	}

	.toggle.secondary.active .circle {
		background-color: #bdbdbd;
	}
`
