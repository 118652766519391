import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../common/colors'
import { alertStatusType } from './_enums'
import { AlertProps } from './_types'
import Warning from '../common/icons/Warning'
import Close from '../common/icons/Close'

const BaseAlert = (props: AlertProps) => {
  const getAlertState = () => {
    if (props.controlled) {
      if (props.isVisible) {
        return alertStatusType.Normal
      } else {
        return alertStatusType.Fade
      }
    }

    return alertStatusType.Normal
  }
  const [state, setState] = React.useState<number>(getAlertState())
  const [timeToClose, setTimeToClose] = React.useState<number | boolean>()

  React.useEffect(() => {
    if (state !== alertStatusType.Normal) {
      const iID = setInterval(() => {
        if (state === alertStatusType.Fade) {
          setState(alertStatusType.Deleted)
        }
        clearInterval(iID)
      }, 600)
    }
  }, [state])

  const handleClose = () => {
    setState(alertStatusType.Fade)
  }

  React.useEffect(() => {
    if (timeToClose) {
      const iID = setInterval(() => {
        handleClose()
        clearInterval(iID)
      }, timeToClose)
    }
  }, [timeToClose])

  React.useEffect(() => {
    if (props.autoclose) {
      if (props.autoclose >= 5) {
        // Es numérico
        setTimeToClose(props.autoclose)
      } else {
        // Es boolean
        setTimeToClose(6000)
      }
    }
  }, [props.autoclose])

  return (
    <div {...props} className={(props.className && props.className) + ' ' + (state === alertStatusType.Normal ? '' : (state === alertStatusType.Fade ? 'deleting' : 'deleted'))}>
      <Warning color='white' size={24} /> &nbsp; { props.children}
      {(props.close || props.onClose !== undefined) &&
        <button className='btn-sm alert-close' onClick={() => (props.controlled && props.onClose !== undefined) ? props.onClose() : handleClose()}>
          <Close color='white' size={28} />
        </button>
      }
    </div>
  )
}

const Alert = styled(BaseAlert)`
  background-color: ${colors.primary.base};
  border-radius: 4px;
  padding: 15px;
  margin: 1rem;
  color: ${colors.grayscale.white};
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-transition: all 0.45s ease-in-out; -moz-transition: all 0.45s ease-in-out; -o-transition: all 0.45s ease-in-out; transition: all 0.45s ease-in-out;

  &.deleting {
    opacity: 0;
  }

  &.deleted {
    display: none;
  }

  svg {
    fill: #ffffff;
  }

  button {
    margin-left: auto;
    background-color: transparent;
    border: none;
    font-size: 28px;
    font-weight: 400;
    padding: 0;
    opacity: .7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`
export default Alert

export const AlertLight = styled(Alert)`
  background-color:${colors.primary.light};
  color:${colors.grayscale.gray100};
  svg {
    fill: ${colors.grayscale.gray100};
  }
`

export const AlertSuccess = styled(Alert)`
  background-color:${colors.secondary.base};
`

export const AlertWarning = styled(Alert)`
  background-color:${colors.warning.base};
  color:${colors.grayscale.gray100};
  svg {
    fill: ${colors.grayscale.gray100};
  }
`

export const AlertError = styled(Alert)`
  background-color:${colors.error.base};
`
