import * as React from 'react'
import styled from 'styled-components'
import { Grid } from '../common/Layout/Grid'
import { Logo } from '../common/Logo'

interface AppBarProps {
  drawer?: React.ReactNode
  utilities?: React.ReactNode
  className?: string
}

const BaseAppBar = (props: AppBarProps) => {
  return (
    <React.Fragment>
      <Grid container className={props.className}>
        {props.drawer &&
          <Grid item className='drawer-appbar'>
            {props.drawer}
          </Grid>}
        <Grid item md={2} className='logo-appbar'>
          <Logo variant='white' sm />
        </Grid>

        <Grid xs={6} md={3} className='utilities-appbar'>
          {props.utilities && props.utilities}
        </Grid>
      </Grid>
    </React.Fragment>

  )
}

export const AppBar = styled(BaseAppBar)`
  width: ${(props) => props.drawer ? 'calc(100% - 60px)' : '100%'};
  background-color: #305cb9;
  height: 75px;
  margin-left: ${(props) => props.drawer ? '30px' : '0'};
  padding: 20px 0;

  .drawer-appbar {
    flex-basis: 40px;
    max-width: 40px;
  }

  .utilities-appbar {
    padding-right: 20px;
    margin-left: auto;
  }

  @media only screen and (min-width : 320px) and (max-width : 480px) {
    width: 100%
    margin: 0;
  }


  @media only screen and (min-width : 321px) {
    width: 100%
    margin: 0;
  }


  @media only screen and (max-width : 320px) {
    width: 100%
    margin: 0;
  }

  @media only screen and (min-width : 768px) and (max-width : 1024px) {

  }


  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {

  }


  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) {

  }

  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {

  }

  @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {

  }

  @media only screen  and (min-width : 1224px) {

  }


  @media only screen  and (min-width : 1824px) {

  }


  @media only screen and (min-width : 320px) and (max-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width : 320px) and (max-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    width: 100%;
    margin: 0;
  }


  @media only screen and (min-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 100%
    margin: 0;
  }


  @media only screen and (min-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }


  @media only screen and (min-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }


  @media only screen and (min-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }


  @media only screen and (min-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }


  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    width: 100%;
    margin: 0;
  }


  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    width: 100%;
    margin: 0;
  }
`
