import * as React from 'react'
import styled from 'styled-components'
import { default as RSelect } from 'react-select'
import { SelectProps } from './types'

const BaseSelect = (props: SelectProps) => {
  const customStyles = {
    control: (provided: React.CSSProperties, state: any) => ({
      ...provided,
      '&:hover': { borderColor: '#005DBA' },
      borderWidth: props.error ? 2 : 1,
      borderColor: props.error ? 'red' : state.isFocused ? '#005DBA' : '#979797',
      boxShadow: state.isFocused ? 'inset 0 0 4px 1px rgba(0,93,186,0.2)' : 'none',
      height: '44px',
      marginTop: '-3px'

    }),
    container: (provided: React.CSSProperties, _state: any) => ({
      ...provided,
      border: 'none'
    }),
    menu: (provided: React.CSSProperties, _state: any) => ({
      ...provided,
      borderRadius: 'none'
    }),
    option: (provided: React.CSSProperties, state: any) => ({
      ...provided,
      color: state.isSelected ? '#595959' : '#595959',
      '&:hover': { background: '#E0E0E0' },
      background: state.isSelected ? '#EEE' : '#FFF'
    })
  }
  return (
    <div className={props.className}>
      {props.label && <label className='label-select'>{props.label}</label>}
      <RSelect
        placeholder={props.placeholder ? props.placeholder : ''}
        options={props.options}
        noOptionsMessage={() => props.noOptionsText ? props.noOptionsText : 'No hay opciones'}
        isMulti={props.multiple}
        value={props.value}
        onChange={props.onChange}
        styles={customStyles}
        isDisabled={props.disabled}
      />
      {props.error && props.errorText && <span className='error-text'>{props.errorText}</span>}
    </div>
  )
}

export const Select = styled(BaseSelect)`
  margin-bottom: 16px;

  label {
    color: #595959;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    display: block;
    margin-top: -3px;
    margin-bottom: 6px;
  }

  span.error-text {
    font-size: 0.75rem;
    color: #f36666;
    display: block;
    margin-top: 5px;
    margin-bottom: 15px;
  }
`
