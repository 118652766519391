import * as React from 'react'

export interface logoProps {
  variant?: string,

  xs?: boolean,
  sm?: boolean,
  md?: boolean,
  lg?: boolean,
}

export const Logo = (props: logoProps) => {
  let width = 170
  let height = 50

  if (props.lg) {
    width = 298
    height = 75
  } else if (props.md) {
    width = 170
    height = 50
  } else if (props.sm) {
    width = 144
    height = 37
  } else if (props.xs) {
    width = 95
    height = 24
  }

  if (props.variant && props.variant === 'white') {
    return (
      <svg width={width} height={height} viewBox='0 0 299 77' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M109.539 54.8685V52.8948C107.763 52.1053 105.395 51.5132 102.829 51.5132C98.2895 51.5132 95.5263 53.2895 95.5263 56.8422V57.0395C95.5263 60.0001 97.8947 61.7764 101.25 61.7764C106.184 61.579 109.539 58.6185 109.539 54.8685ZM85.0658 57.2369C85.0658 48.7501 91.3816 44.8027 100.263 44.8027C104.013 44.8027 106.776 45.3948 109.342 46.3816V45.7895C109.342 41.2501 106.579 38.8816 101.25 38.8816C97.1053 38.8816 94.3421 39.6711 90.9868 40.8553L88.4211 32.5659C92.5658 30.7895 96.5132 29.408 103.026 29.408C108.75 29.408 113.092 30.9869 115.658 33.7501C118.421 36.5132 119.803 40.8553 119.803 45.9869V68.2895H109.539V64.1448C106.776 67.1053 103.224 69.079 97.8947 69.079C90.9869 69.079 85.0658 64.9343 85.0658 57.2369Z' fill='white' />
        <path d='M130.066 30.0001H140.724V35.5264C143.092 32.3685 146.25 29.408 151.776 29.408C159.868 29.408 164.408 34.9343 164.408 43.6185V68.4869H153.75V46.9737C153.75 41.8422 151.382 39.2764 147.237 39.2764C143.092 39.2764 140.526 41.8422 140.526 46.9737V68.2895H129.868V30.0001H130.066Z' fill='white' />
        <path d='M174.868 30.0001H185.526V35.5264C187.895 32.3685 191.053 29.408 196.579 29.408C204.671 29.408 209.211 34.9343 209.211 43.6185V68.4869H198.553V46.9737C198.553 41.8422 196.184 39.2764 192.039 39.2764C187.895 39.2764 185.329 41.8422 185.329 46.9737V68.2895H174.671V30.0001H174.868Z' fill='white' />
        <path d='M244.145 46.1841C243.553 41.2499 240.789 38.092 236.25 38.092C231.908 38.092 228.947 41.2499 228.158 46.1841H244.145ZM217.697 49.342C217.697 38.2894 225.395 29.2104 236.25 29.2104C248.684 29.2104 254.408 39.0789 254.408 49.9341C254.408 50.7236 254.408 51.7105 254.211 52.8947H228.158C229.145 57.8289 232.5 60.3947 237.237 60.3947C240.789 60.3947 243.355 59.2104 246.316 56.4473L252.434 61.9736C248.882 66.3157 243.947 69.0789 237.039 69.0789C226.184 69.0789 217.697 60.9868 217.697 49.342Z' fill='white' />
        <path d='M263.684 29.9999H274.342V37.6972C276.513 32.3683 280.066 29.013 286.382 29.2104V40.6578H285.789C278.684 40.6578 274.342 44.9999 274.342 54.0788V68.2894H263.684V29.9999Z' fill='white' />
        <path d='M16.1842 8.88158C12.6316 8.88158 9.67106 11.8421 9.67106 15.5921V61.3816C9.67106 65.1316 12.6316 68.0921 16.1842 68.0921H66.1184V15.5921C66.1184 11.8421 63.1579 8.88158 59.6053 8.88158H16.1842ZM74.6053 76.9737H16.1842C7.89474 76.9737 0.986847 70.0658 0.986847 61.3816V15.5921C0.986847 7.10526 7.69737 0 16.1842 0H59.6053C67.8947 0 74.8026 6.90789 74.8026 15.5921V76.9737H74.6053Z' fill='white' />
        <path d='M37.3026 32.5658H22.3026V22.5H62.9605V32.5658H47.9605V72.4342H37.3026V32.5658Z' fill='white' />
        <path d='M294.868 68.8817C292.895 68.8817 291.118 70.658 291.118 72.6317C291.118 74.8027 292.697 76.3817 294.868 76.3817C296.842 76.3817 298.618 74.6053 298.618 72.6317C298.421 70.4606 296.842 68.8817 294.868 68.8817ZM294.868 76.9738C292.5 76.9738 290.724 75.0001 290.724 72.6317C290.724 70.2632 292.697 68.2896 294.868 68.2896C297.237 68.2896 299.013 70.2632 299.013 72.6317C299.013 75.0001 297.039 76.9738 294.868 76.9738Z' fill='white' />
        <path d='M295.066 71.0527H293.882V72.6316H295.066C295.658 72.6316 296.053 72.2369 296.053 71.8421C296.053 71.25 295.658 71.0527 295.066 71.0527ZM296.053 75L294.868 73.4211H293.882V75H293.092V70.2632H295.066C296.053 70.2632 296.842 70.8553 296.842 71.8421C296.842 72.6316 296.447 73.0263 295.658 73.2237L296.842 75H296.053Z' fill='white' />
      </svg>
    )
  } else {
    return (
      <svg width={width} height={height} viewBox='0 0 298 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M84.1166 55.8548V55.7127C84.1166 47.5555 90.3376 43.7897 99.213 43.7897C102.983 43.7897 105.711 44.4173 108.367 45.3252V44.6996C108.367 40.3042 105.64 37.8627 100.331 37.8627C96.2736 37.8627 93.4114 38.6325 89.9894 39.8858L87.3309 31.7956C91.4551 29.9818 95.5081 28.7976 101.868 28.7976C107.667 28.7976 111.861 30.3331 114.515 32.9818C117.31 35.7687 118.568 39.8858 118.568 44.9068V66.5897H108.296V62.5456C105.711 65.4075 102.148 67.2904 96.9759 67.2904C89.9182 67.2904 84.1166 63.2423 84.1166 55.8548ZM108.506 53.4114V51.5305C106.69 50.6956 104.312 50.1371 101.727 50.1371C97.1835 50.1371 94.3886 51.9489 94.3886 55.2943V55.4364C94.3886 58.2923 96.7642 59.968 100.19 59.968C105.151 59.968 108.506 57.2483 108.506 53.4114Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M128.983 29.2167H139.605V34.518C142.054 31.3819 145.195 28.52 150.579 28.52C158.618 28.52 163.294 33.8213 163.294 42.399V66.5924H152.674V45.7444C152.674 40.7233 150.3 38.1457 146.243 38.1457C142.192 38.1457 139.605 40.7233 139.605 45.7444V66.5924H128.983V29.2167Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M173.785 29.2167H184.401V34.518C186.848 31.3819 189.993 28.52 195.377 28.52C203.412 28.52 208.092 33.8213 208.092 42.399V66.5924H197.474V45.7444C197.474 40.7233 195.097 38.1457 191.042 38.1457C186.985 38.1457 184.401 40.7233 184.401 45.7444V66.5924H173.785V29.2167Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M216.76 48.1162V47.9741C216.76 37.3064 224.378 28.5215 235.279 28.5215C247.79 28.5215 253.518 38.2142 253.518 48.807C253.518 49.6517 253.449 50.6228 253.38 51.6017H227.309C228.361 56.4116 231.714 58.9221 236.466 58.9221C240.034 58.9221 242.619 57.805 245.551 55.0873L251.633 60.4577C248.14 64.7781 243.106 67.4287 236.329 67.4287C225.074 67.4287 216.76 59.5498 216.76 48.1162ZM243.175 44.9721C242.548 40.2353 239.753 37.03 235.279 37.03C230.878 37.03 228.013 40.1623 227.175 44.9721H243.175Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M262.678 29.217H273.298V36.7505C275.466 31.5913 278.958 28.242 285.25 28.5203V39.6124H284.692C277.628 39.6124 273.298 43.8597 273.298 52.7867V66.5926H262.678V29.217Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M73.6662 75H15.1637C6.80248 75 0 68.2125 0 59.8678V15.1342C0 6.78947 6.80248 0 15.1637 0H58.4986C66.8637 0 73.6662 6.78947 73.6662 15.1342V75ZM15.1637 8.64671C11.5775 8.64671 8.66382 11.5559 8.66382 15.1342V59.8678C8.66382 63.4421 11.5775 66.3533 15.1637 66.3533H64.9984V15.1342C64.9984 11.5559 62.0848 8.64671 58.4986 8.64671H15.1637Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M36.2399 31.7679H21.3531V21.866H61.8832V31.7679H47.0004V70.6791H36.2399V31.7679Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M292.586 75.0002C289.608 75.0002 287.184 72.5825 287.184 69.6101C287.184 66.6417 289.608 64.2219 292.586 64.2219C295.565 64.2219 297.989 66.6417 297.989 69.6101C297.989 72.5825 295.565 75.0002 292.586 75.0002ZM292.586 64.9068C289.987 64.9068 287.871 67.0167 287.871 69.6101C287.871 72.2055 289.987 74.3153 292.586 74.3153C295.186 74.3153 297.302 72.2055 297.302 69.6101C297.302 67.0167 295.186 64.9068 292.586 64.9068Z' fill='#005CB8' />
        <path fillRule='evenodd' clipRule='evenodd' d='M294.208 72.5722L292.762 70.5255H291.467V72.5722H290.432V66.6985H293.057C294.41 66.6985 295.243 67.411 295.243 68.5616C295.243 69.5347 294.663 70.1209 293.838 70.3498L295.429 72.5722H294.208ZM292.972 67.638H291.467V69.6097H292.98C293.72 69.6097 294.192 69.2268 294.192 68.6209C294.192 67.9735 293.737 67.638 292.972 67.638Z' fill='#005CB8' />
      </svg>
    )
  }
}
