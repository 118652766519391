import * as React from 'react';
import { logoProps } from './_types';
import IconBase from './IconBase';

const Admin = ( props:logoProps ) => {
  return (
    <IconBase {...props}>
      <mask id="maskadm" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="30" height="28">
        <path d="M12 4C14.7614 4 17 6.23858 17 9C17 11.7614 14.7614 14 12 14C9.23858 14 7 11.7614 7 9C7.00314 6.23988 9.23988 4.00314 12 4ZM12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2ZM22 30H20V25C19.9967 22.2399 17.7601 20.0033 15 20H9C6.23992 20.0033 4.00325 22.2399 4 25V30H2V25C2.00452 21.1359 5.13588 18.0045 9 18H15C18.8641 18.0045 21.9955 21.1359 22 25V30ZM25 16.18L22.41 13.59L21 15L25 19L32 12L30.59 10.59L25 16.18Z" fill="white"/>
      </mask>
      <g mask="url(#maskadm)">
        <path d="M12 4C14.7614 4 17 6.23858 17 9C17 11.7614 14.7614 14 12 14C9.23858 14 7 11.7614 7 9C7.00314 6.23988 9.23988 4.00314 12 4ZM12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2ZM22 30H20V25C19.9967 22.2399 17.7601 20.0033 15 20H9C6.23992 20.0033 4.00325 22.2399 4 25V30H2V25C2.00452 21.1359 5.13588 18.0045 9 18H15C18.8641 18.0045 21.9955 21.1359 22 25V30ZM25 16.18L22.41 13.59L21 15L25 19L32 12L30.59 10.59L25 16.18Z" />
      </g>
    </IconBase>
  )
}

export default Admin;