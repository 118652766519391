import * as React from 'react'
import { logoProps } from './_types'
import IconBase from './IconBase'

const FirstPage = (props: logoProps) => {
  return (
    <IconBase {...props}>
      <mask id='maskfirstpage' mask-type='alpha' maskUnits='userSpaceOnUse' x='8' y='4' width='18' height='24'>
        <path d='M14 16L24 6L25.414 7.414L16.828 16L25.414 24.586L24 26L14 16ZM8 4H10V28H8V4Z' fill='white'/>
      </mask>
      <g mask='url(#maskfirstpage)'>
        <path d='M14 16L24 6L25.414 7.414L16.828 16L25.414 24.586L24 26L14 16ZM8 4H10V28H8V4Z'/>
      </g>
    </IconBase>
  )
}

export default FirstPage
