import * as React from 'react'
import { logoProps } from './_types'
import IconBase from './IconBase'

const ChevronRight = (props: logoProps) => {
  return (
    <IconBase {...props}>
      <mask id='maskcvr' mask-type='alpha' maskUnits='userSpaceOnUse' x='10' y='6' width='12' height='20'>
        <path d='M22 16L12 26L10.586 24.586L19.172 16L10.586 7.414L12 6L22 16Z' fill='white'/>
      </mask>
      <g mask='url(#maskcvr)'>
        <path d='M22 16L12 26L10.586 24.586L19.172 16L10.586 7.414L12 6L22 16Z'/>
      </g>
    </IconBase>
  )
}

export default ChevronRight
