import * as React from 'react'
import { logoProps, iconExtraProps } from './_types'

const BasicSvg = (props: logoProps & iconExtraProps) => {
  let width = 32
  let height = 32

  if (props.size) {
    width = props.size
    height = props.size
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      {props.children}
    </svg>
  )
}

const IconBase = BasicSvg

export default IconBase
