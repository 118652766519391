import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../colors'
import TableHeader from './components/TableHeader'
import TableContent from './components/TableContent'
import { hexToRgb } from '../helpers'
import { Pagination } from '../../pagination'
import { paginationProps } from '../../pagination/_types'

export interface tableProps {
  children?: React.ReactNode | React.ReactNode[];
  dataContent?: any[];
  dataHeader?: any;
  noResults?: string,
  striped?: boolean,
  bordered?: boolean | 1 | 0,
  sm?: boolean | 1 | 0,
  borderless?: boolean | 1 | 0,
  hover?: boolean | 1 | 0,
  onSort?: () => void,

  totalPages?: number,
  activePage?: number,
  clickAction?: (data: paginationProps) => void,
}

const BaseTable = (props: tableProps) => <table {...props}>{props.children}</table>

const colorOdd = hexToRgb(colors.primary.light)

const TableBox = styled(BaseTable)`
  width: 100%;
  margin-bottom: 1rem;
  color: ${colors.grayscale.gray100};
  border-collapse: collapse;
  border-spacing: 0;

  tbody {
    tr {
      &:nth-of-type(odd) {
        ${props => (props.striped) && 'background-color: rgba(' + (colorOdd && colorOdd.r) + ', ' + (colorOdd && colorOdd.g) + ', ' + (colorOdd && colorOdd.b) + ', .8);'}
      }
    }
  }

  ${props => (props.bordered) && 'border: 1px solid ' + (colors.grayscale.gray20) + '; th, td {border: 1px solid ' + (colors.grayscale.gray20) + ';}'}
  ${props => (props.sm) && 'th, td {padding: 0.1rem 5px; line-height: 1.2rem;}'} ${props => (props.borderless) && 'th, td, thead th, tbody + tbody {border: 0;}'} ${props => (props.hover) && 'tr { &:hover { color: #212529; background-color: rgba(' + (colorOdd && colorOdd.r) + ', ' + (colorOdd && colorOdd.g) + ', ' + (colorOdd && colorOdd.b) + ', .9); } }'} @media only screen and(max-width: 61 em) {
  display: block;
  ${props => (props.sm) && 'td:before { top: 3px; }'}
}
`

const Table = (props: tableProps) => {
  return (
    <React.Fragment>
      <TableBox {...props}>
        {props.dataHeader &&
        <TableHeader data={props.dataHeader}/>
        }

        {props.dataContent && <TableContent data={props.dataContent}/>}

        {props.children && props.children}
      </TableBox>
      {props.totalPages &&
      <div className='table_pagination' style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination totalPages={props.totalPages} activePage={props.activePage && props.activePage}
          clickAction={props.clickAction && props.clickAction} mini/>
      </div>
      }
    </React.Fragment>
  )
}

export default Table
