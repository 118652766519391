import * as React from 'react'
import { RadioButtonProps } from './types'

export const Radio = (props: RadioButtonProps) => {
  return (
    <label>
      {props.label && props.label}
      <input type='radio' {...props} checked={props.checked} />
      <span className='checkmark'></span>
    </label>
  )
}
