import * as React from 'react';
import { logoProps } from './_types';
import IconBase from './IconBase';

const MobileCheck = ( props:logoProps ) => {
  return (
    <IconBase {...props}>
      <mask id="mobilecheck" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="2" width="20" height="28">
        <path d="M24 2H8C6.89678 2.00324 6.00324 2.89678 6 4V28C6.00324 29.1032 6.89678 29.9968 8 30H24C25.1032 29.9968 25.9968 29.1032 26 28V4C25.9968 2.89678 25.1032 2.00324 24 2ZM24 4V6H8V4H24ZM24 8V24H8V8H24ZM8 28V26H24V28H8ZM14 18.18L11.41 15.59L10 17L14 21L22 13L20.59 11.59L14 18.18Z" fill="white"/>
      </mask>
      <g mask="url(#mobilecheck)">
        <path d="M24 2H8C6.89678 2.00324 6.00324 2.89678 6 4V28C6.00324 29.1032 6.89678 29.9968 8 30H24C25.1032 29.9968 25.9968 29.1032 26 28V4C25.9968 2.89678 25.1032 2.00324 24 2ZM24 4V6H8V4H24ZM24 8V24H8V8H24ZM8 28V26H24V28H8ZM14 18.18L11.41 15.59L10 17L14 21L22 13L20.59 11.59L14 18.18Z" />
      </g>
    </IconBase>
  )
}

export default MobileCheck;