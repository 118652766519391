import * as React from 'react'
// eslint-disable-next-line no-unused-vars
import { logoProps } from './_types'
import IconBase from './IconBase'

const Menu = (props: logoProps) => {
  return (
    <IconBase {...props}>
      <mask id='menu' mask-type='alpha' maskUnits='userSpaceOnUse' x='4' y='6' width='24' height='20'>
        <path d='M4 6H28V8H4V6ZM4 24H28V26H4V24ZM4 15H28V17H4V15Z' fill='white'/>
      </mask>
      <g mask='url(#menu)'>
        <path d='M4 6H28V8H4V6ZM4 24H28V26H4V24ZM4 15H28V17H4V15Z'/>
      </g>
    </IconBase>
  )
}

export default Menu
