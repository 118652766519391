import * as React from 'react';
import getColor from './_helper'
import { logoProps } from './_types';
import IconBase from './IconBase';

const View = ( props:logoProps ) => {
  let color:string = getColor( props.color );

  return (
    <IconBase {...props}>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="5" width="30" height="22">
        <path d="M30.94 15.66C28.5339 9.44338 22.6612 5.25312 16 5C9.33879 5.25312 3.46614 9.44338 1.06 15.66C0.980569 15.8797 0.980569 16.1203 1.06 16.34C3.46614 22.5566 9.33879 26.7469 16 27C22.6612 26.7469 28.5339 22.5566 30.94 16.34C31.0194 16.1203 31.0194 15.8797 30.94 15.66ZM16 25C10.7 25 5.1 21.07 3.07 16C5.1 10.93 10.7 7 16 7C21.3 7 26.9 10.93 28.93 16C26.9 21.07 21.3 25 16 25ZM16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10ZM16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20Z" fill="white"/>
      </mask>
      <g mask="url(#mask0)">
        <path d="M30.94 15.66C28.5339 9.44338 22.6612 5.25312 16 5C9.33879 5.25312 3.46614 9.44338 1.06 15.66C0.980569 15.8797 0.980569 16.1203 1.06 16.34C3.46614 22.5566 9.33879 26.7469 16 27C22.6612 26.7469 28.5339 22.5566 30.94 16.34C31.0194 16.1203 31.0194 15.8797 30.94 15.66ZM16 25C10.7 25 5.1 21.07 3.07 16C5.1 10.93 10.7 7 16 7C21.3 7 26.9 10.93 28.93 16C26.9 21.07 21.3 25 16 25ZM16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10ZM16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20Z" fill={color} />
      </g>
    </IconBase>
  )
}

export default View;