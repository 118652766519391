import * as React from 'react'
import { TextFieldProps } from './types'
import styled from 'styled-components'

const BaseTextField = (props: TextFieldProps) => {
  const { className } = props
  return (
    <div className={className}>
      <input {...props} />
      {props.error && props.errorText && <span>{props.errorText}</span>}
      {props.label && <label>{props.label}</label>}
    </div>
  )
}

export const TextField = styled(BaseTextField)`
  display: block;
  padding-top: 15px;
  margin-bottom: ${props => props.error ? '0px' : '20px'};
  position: relative;

  input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    background-image: none;
    color: #595959;
    border: ${props => props.error ? '2px solid #f36666' : '1px solid #979797'};
    outline: none;
    width: 100%;
    padding: 0 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    font-size: 1rem;
    font-family: inherit;
    line-height: inherit;
    height: ${props => props.small ? '30px' : '44px'};
  }

  ::placeholder {
    color: ${props => props.label ? props.label.length > 0 ? 'transparent' : 'inherit' : 'inherit'};
  }

  input:disabled {
    cursor: not-allowed;
    border-color: #979797;
    background-color: rgba(235, 235, 235, 0.4);
    opacity: 1;
    color: #595959;
  }

  input:focus {
    border-color: #005DBA;
    border-width: 1px;
    box-shadow: inset 0 0 4px 1px rgba(0, 93, 186, 0.2);
  }

  span {
    font-size: 0.75rem;
    color: #f36666;
    display: block;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  label {
    position: absolute;
    -webkit-transform: ${props => props.value ? props.value.length > 0 ? 'translate(0, -5px)' : 'translate(12px, 15px)' : 'translate(12px, 15px)'};
    transform: ${props => props.value ? props.value.length > 0 ? 'translate(0, -5px)' : 'translate(12px, 15px)' : 'translate(12px, 15px)'};
    font-size: ${props => props.value ? props.value.length > 0 ? '12px' : '16px' : '16px'};
    line-height: ${props => getLabelLineHeight(props)};
    color: #595959;
    text-overflow: ${props => props.value ? props.value.length > 0 ? 'ellipsis' : 'clip' : 'clip'};
    cursor: text;
    pointer-events: none;
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    font-weight: 400;
    overflow-x: hidden;
    white-space: nowrap;
    -webkit-transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -o-transition: all .15s ease-out;
    transition: all .15s ease-out;
  }

  input:focus ~ label {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    font-size: 12px;
    line-height: 15px;
    text-overflow: ellipsis;
    color: #005DBA;
  }
`

const getLabelLineHeight = (props: TextFieldProps) => {
  if (props.value) {
    if (props.value.length > 0) {
      return '15px'
    } else {
      if (props.small) {
        return '30px'
      } else {
        return '44px'
      }
    }
  } else {
    if (props.small) {
      return '30px'
    } else {
      return '44px'
    }
  }
}
