import * as React from 'react'
import styled from 'styled-components'

export interface containerProps {
  fluid?: boolean,
  withDrawer?: boolean,
  children: React.ReactNode,
  className?: string,

}

const GridDiv = styled.div<containerProps>`
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (min-width: 48em) {
    ${props => !props.fluid && 'width: 46rem;'}
    ${props => props.withDrawer && 'padding-left: 60px;'}


  }
  @media only screen and (min-width: 62em) {
    ${props => !props.fluid && 'width: 61rem;'}
    ${props => props.withDrawer && 'padding-left: 60px;'}

  }

  @media only screen and (min-width: 75em) {
    ${props => !props.fluid && 'width: 71rem;'}
    ${props => props.withDrawer && 'padding-left: 60px;'}
  }

`

export const Container = (props: containerProps) => {
  return (
    <GridDiv {...props} className={props.className ? props.className : ''} >
      {props.children}
    </GridDiv>
  )
}
