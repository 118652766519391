import * as React from 'react'
import styled from 'styled-components'
import { tableRowProps, TableItemWithChildrensProps } from '../types'
import TableRow from './TableRow'
import TableCell from './TableCell'

const TableHeadItem = styled.thead<TableItemWithChildrensProps>`
  @media only screen and (max-width: 61em) {
    display: block;

    tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }
`

const TableHeader = (props: tableRowProps) => {
  return (
    <TableHead>
      <TableRow>
        {props.data &&
        props.data.map((item: any, index: number) => {
          return <TableCell component='th' key={index}>{item === 'null' ? ' ' : item}</TableCell>
        })
        }
      </TableRow>
    </TableHead>
  )
}

const TableHead = (props: TableItemWithChildrensProps) => {
  return (
    <TableHeadItem>
      {props.children}
    </TableHeadItem>
  )
}

export default TableHeader
export { TableHead }
