import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../common/colors'
import FirstPage from '../common/icons/FirstPage'
import LastPage from '../common/icons/LastPage'
import ChevronRight from '../common/icons/ChevronRight'
import ChevronLeft from '../common/icons/ChevronLeft'

import { pagintationProps, paginationProps, liProps, linkProps } from './_types'

const BaseLi = (props: liProps) => {
  return (
    <li {...props}>
      {props.children}
    </li>
  )
}

const CustomLi = styled(BaseLi)`
  margin: 0 1px!important;
  width: auto;
  float: left;
`

const BaseLink = (props: linkProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (props.onClickLink && (props.active === false || props.active === undefined)) {
      props.onClickLink()
    }
  }

  return (
    <CustomLi>
      <a href='#' {...props} onClick={handleClick}>
        {props.children}
      </a>
    </CustomLi>
  )
}

const CustomItem = styled(BaseLink)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary.base};
  text-decoration: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  letter-spacing: -1.6px;
  text-align: center;
  box-sizing: inherit;
  &:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;

    svg {
      fill: #0056b3;
    }
  }
  &:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  svg {
    fill: ${colors.primary.base};
  }
  ${props => (props.active) && 'z-index: 3; color: ' + (colors.grayscale.white) + '; background-color: ' + (colors.primary.brilliant) + '; text-decoration: none;'}
  ${props => (props.disabled) && 'color: ' + (colors.grayscale.gray100) + '; pointer-events: none; cursor: auto; background-color: ' + (colors.grayscale.white) + '; svg{fill:' + (colors.grayscale.gray100) + ';}'}

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;


`

const BasePagination = (props: pagintationProps) => {
  const [activePage, setActivePage] = React.useState<number>(props.activePage ? props.activePage : 1)
  const [totalPage] = React.useState<number>(props.totalPages)

  const [firstN, setFirstN] = React.useState<number>(1)
  const [secondN, setSecondN] = React.useState<number>(2)
  const [thirdN, setThirdN] = React.useState<number>(3)

  const handleClick = (propsAction: paginationProps) => {
    const dataObject: paginationProps = {
      event: 'click',
      type: propsAction.type
    }

    if (propsAction.goTo) {
      dataObject.goTo = propsAction.goTo
    } else {
      if (propsAction.type === 'next_page') {
        dataObject.goTo = activePage + 1
      } else if (propsAction.type === 'prev_page') {
        dataObject.goTo = activePage - 1
      } else if (propsAction.type === 'first_page') {
        dataObject.goTo = 1
      } else if (propsAction.type === 'last_page') {
        dataObject.goTo = props.totalPages
      }
    }

    if (props.clickAction) {
      props.clickAction(dataObject)
    }
  }

  React.useEffect(() => {
    // Validamos si pagina activa es menor, sino retorna error
    if (activePage <= totalPage) {
      // Primero verificamos si es micro o mini
      if (props.micro) {
        setFirstN(activePage)
      } else if (props.mini) {
        if (props.totalPages <= 3) {
          setFirstN(1)
          if (props.totalPages >= 2) {
            setSecondN(2)
          }
          if (props.totalPages >= 3) {
            setThirdN(3)
          }
        } else if (activePage === props.totalPages) {
          setFirstN(props.totalPages - 2)
          setSecondN(props.totalPages - 1)
          setThirdN(props.totalPages)
        } else {
          if (activePage === 1) {
            setFirstN(1)
            setSecondN(2)
            setThirdN(3)
          } else {
            setFirstN(activePage - 1)
            setSecondN(activePage)
            setThirdN(activePage + 1)
          }
        }
      } else {
        if (props.totalPages >= 7) {
          // Revisar el máximo, el mínimo
          if (props.totalPages - activePage < 3) {
            setFirstN(props.totalPages - 4)
            setSecondN(props.totalPages - 3)
            setThirdN(props.totalPages - 2)
          } else if (activePage > 3) {
            setFirstN(activePage - 1)
            setSecondN(activePage)
            setThirdN(activePage + 1)
          } else {
            setFirstN(3)
            setSecondN(4)
            setThirdN(5)
          }
        } else {
          if (props.totalPages >= 3) {
            setFirstN(3)
            if (props.totalPages >= 4) {
              setSecondN(4)
            }
            if (props.totalPages >= 5) {
              setThirdN(5)
            }
          }
        }
      }
    } else {
      throw new Error('Error en componente paginación')
    }
  }, [activePage])

  React.useEffect(() => {
    if (props.activePage) {
      setActivePage(props.activePage)
    }
  }, [props.activePage])

  return (
    <nav aria-label='pagination navigation' className='pagination'>
      <ul {...props}>
        {props.showFirstPage &&
          <CustomItem disabled={activePage === 1 && true} onClickLink={() => { handleClick({ type: 'first_page' }) }}>
            <FirstPage size={18} />
          </CustomItem>
        }
        {props.hidePrev ||
          <CustomItem disabled={activePage === 1 && true} onClickLink={() => { handleClick({ type: 'prev_page' }) }}>
            <ChevronLeft size={18} />
          </CustomItem>
        }

        {(props.micro || props.mini) ||
          <React.Fragment>
            <CustomItem active={activePage === 1 && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: 1 }) }}>
              1
            </CustomItem>
            {activePage >= 5 && props.totalPages > 7
              ? <CustomItem disabled>
                ...
              </CustomItem>
              : (props.totalPages >= 2 &&
                <CustomItem active={activePage === 2 && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: 2 }) }}>
                  2
                </CustomItem>
              )
            }
          </React.Fragment>
        }

        {(props.micro) || (props.mini) || (props.totalPages >= 3 && !props.mini && !props.micro)
          ? <CustomItem active={activePage === firstN && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: firstN }) }}>
            {firstN}
          </CustomItem>
          : null
        }
        {(props.mini && props.totalPages >= 2 && !props.micro) || (props.totalPages >= 4 && !props.mini && !props.micro)
          ? <CustomItem active={activePage === secondN && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: secondN }) }}>
            {secondN}
          </CustomItem>
          : null
        }
        {(props.mini && props.totalPages >= 3 && !props.micro) || (props.totalPages >= 5 && !props.mini && !props.micro)
          ? <CustomItem active={activePage === thirdN && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: thirdN }) }}>
            {thirdN}
          </CustomItem>
          : null
        }

        {(props.micro || props.mini) ||
          <React.Fragment>
            {props.totalPages - activePage >= 4 && props.totalPages > 7
              ? <CustomItem disabled>
                ...
              </CustomItem>
              : (props.totalPages >= 7 &&
                <CustomItem active={activePage === (props.totalPages - 1) && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: props.totalPages - 1 }) }}>
                  {props.totalPages - 1}
                </CustomItem>
              )
            }
            {props.totalPages >= 6 &&
              <CustomItem active={activePage === props.totalPages && true} onClickLink={() => { handleClick({ type: 'custom_page', goTo: props.totalPages }) }}>
                {props.totalPages}
              </CustomItem>
            }
          </React.Fragment>
        }

        {props.hideNext ||
          <CustomItem disabled={activePage === props.totalPages && true} onClickLink={() => { handleClick({ type: 'next_page' }) }} >
            <ChevronRight size={18} />
          </CustomItem>
        }
        {props.showLastPage &&
          <CustomItem disabled={activePage === props.totalPages && true} onClickLink={() => { handleClick({ type: 'last_page' }) }} >
            <LastPage size={18} />
          </CustomItem>
        }
      </ul>
    </nav>
  )
}

const Pagination = styled(BasePagination)`
  display: block;
  padding-left: 0;
  list-style: none;

  li.page-item {


		.page-link {

		}

  }
`

export default Pagination
