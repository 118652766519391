import * as React from 'react';
import { logoProps } from './_types';
import IconBase from './IconBase';

const DocumentAdd = ( props:logoProps ) => {
  return (
    <IconBase {...props}>
      <mask id="documentadd" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="1" width="21" height="29">
        <path d="M25.7 9.3L18.7 2.3C18.5217 2.10248 18.266 1.99286 18 2H8C6.89543 2 6 2.89543 6 4V28C6 29.1046 6.89543 30 8 30H24C25.1046 30 26 29.1046 26 28V10C26.0071 9.73403 25.8975 9.47826 25.7 9.3ZM18 4.4L23.6 10H18V4.4ZM24 28H8V4H16V10C16 11.1046 16.8954 12 18 12H24V28ZM21 19H17V15H15V19H11V21H15V25H17V21H21V19Z" fill="white"/>
      </mask>
      <g mask="url(#documentadd)">
        <path d="M25.7 9.3L18.7 2.3C18.5217 2.10248 18.266 1.99286 18 2H8C6.89543 2 6 2.89543 6 4V28C6 29.1046 6.89543 30 8 30H24C25.1046 30 26 29.1046 26 28V10C26.0071 9.73403 25.8975 9.47826 25.7 9.3ZM18 4.4L23.6 10H18V4.4ZM24 28H8V4H16V10C16 11.1046 16.8954 12 18 12H24V28ZM21 19H17V15H15V19H11V21H15V25H17V21H21V19Z" />
      </g>
    </IconBase>
  )
}

export default DocumentAdd;