/* eslint-disable no-unused-vars */
import * as React from 'react'
import styled from 'styled-components'
import { SurfaceProps } from './types'
import { colors } from '../colors'
import {
  getShadowCss,
  getBorderRadiusCss,
  getBorderCss,
  getBorderColorCss
} from '../helpers'

const BaseSurface = (props: SurfaceProps) => (
  <div {...props}>{props.children}</div>
)

export const Surface = styled(({ nopadding, nomargin, ...props }) => <BaseSurface {...props} />)`
  background-color: ${colors.grayscale.white};
  padding: ${(props) => (props.nopadding ? '0 !important' : '1rem')};
  border-radius: 0;
  margin: ${(props) => (props.nomargin ? '0 !important' : '0.5rem')};
  color: ${colors.grayscale.gray100};
  box-shadow: ${(props) =>
    props.shadow ? getShadowCss(props.shadow) : 'none'};
  border-radius: ${(props) =>
    props.radius ? getBorderRadiusCss(props.radius) : 'none'};
  border-style: ${(props) => (props.borderwidth ? 'solid' : 'none')};
  border-width: ${(props) =>
    props.borderwidth ? getBorderCss(props.borderwidth) : 'none'};
  border-color: ${(props) =>
    props.bordercolor ? getBorderColorCss(props.bordercolor) : 'none'};
`

export const TransparentSurface = styled(Surface)`
background-color: transparent;
`

export const PrimarySurface = styled(Surface)`
  background-color: ${colors.primary.base};
  color: ${colors.grayscale.white};
`

export const SecondarySurface = styled(Surface)`
  background-color: ${colors.secondary.base};
  color: ${colors.grayscale.white};
`

export const TertiarySurface = styled(Surface)`
  background-color: ${colors.tertiary.base};
  color: ${colors.grayscale.white};
`

export const WarningSurface = styled(Surface)`
  background-color: ${colors.warning.base};
  color: ${colors.grayscale.black};
  font-weight: 700;
`

export const AltwSurface = styled(Surface)`
  background-color: ${colors.altw.base};
  color: ${colors.grayscale.white};
  font-weight: 700;
`

export const ErrorSurface = styled(Surface)`
  background-color: ${colors.error.base};
  color: ${colors.grayscale.white};
  font-weight: 700;
`
