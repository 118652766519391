import * as React from 'react'
import { logoProps } from './_types'
import IconBase from './IconBase'

const Close = (props: logoProps) => {
  return (
    <IconBase {...props}>
      <mask id='maskclose' mask-type='alpha' maskUnits='userSpaceOnUse' x='8' y='8' width='16' height='16'>
        <path
          d='M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586L17.414 16Z'
          fill='white'/>
      </mask>
      <g mask='url(#maskclose)'>
        <path
          d='M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586L17.414 16Z'/>
      </g>
    </IconBase>
  )
}

export default Close
