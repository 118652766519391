import * as React from 'react'
import styled from 'styled-components'
import { tableCellProps } from '../types'
import { colors } from '../../colors'

const TableCellTh = styled.th<tableCellProps>`
  padding: 0.75rem 5px;
  vertical-align: middle;
  border-bottom: 2px solid ${colors.grayscale.gray10};
  text-align: left;

  @media only screen and (max-width: 61em) {
    display: block;
  }
`

const TableCellTd = styled.td<tableCellProps>`
  padding: 0.75rem 5px;
  vertical-align: middle;
  border-bottom: 1px solid ${colors.grayscale.gray10};
  word-break: break-all;
  text-align: left;

  @media only screen and (max-width: 61em) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;

    &:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }

    &:before {
      content: attr(data-title);
      padding-top: 7px;
    }
  }
`

const TableCell = (props: tableCellProps) => {
  const onSortClick = () => {
    props.onSort && props.onSort()
  }

  if (props.component && props.component === 'th') {
    return (
      <TableCellTh onClick={onSortClick} {...props.className && { className: props.className }}>
        {props.children && props.children}
      </TableCellTh>
    )
  } else {
    if (props.onSort) {
      throw new Error("An error has occured in TableCell component, you can use onSort only when component is 'th'")
    }
    return (
      <TableCellTd data-title={props.title && props.title} {...props.colspan && { colSpan: props.colspan }}>
        {props.children && props.children}
      </TableCellTd>
    )
  }
}

export default TableCell
