export const colors = {
  primary: {
    base: '#005DBA',
    brilliant: '#0080FF',
    soft: '#3B80C6',
    light: '#E5EEF8',
    strong: '#003589',
    shadow: '#12263B',
    dark: '#001326'
  },
  secondary: {
    base: '#79B900',
    brilliant: '#A0E221',
    soft: '#95C63B',
    light: '#F2F9E6',
    strong: '#588700',
    shadow: '#2C3B12',
    dark: '#172400'
  },
  tertiary: {
    base: '#39008C',
    brilliant: '#910899',
    soft: '#7E41D9',
    light: '#F2E3F5',
    strong: '#4C2254',
    shadow: '#240059',
    dark: '#1B0043'
  },
  warning: {
    base: '#FFD900',
    brilliant: '#FCF320',
    soft: '#FFE44D',
    light: '#FFFDE5',
    strong: '#CCAD00',
    shadow: '#807226',
    dark: '#564900'
  },
  altw: {
    base: '#FF8C00',
    brilliant: '#FFC50D',
    soft: '#FFAF4D',
    light: '#FFF3E0',
    strong: '#CC7000',
    shadow: '#805726',
    dark: '#5F3400'
  },
  error: {
    base: '#FF6666',
    brilliant: '#FF8A59',
    soft: '#FFB3B3',
    light: '#FFEAED',
    strong: '#CC5252',
    shadow: '#805959',
    dark: '#803333'
  },
  grayscale: {
    black: '#000000',
    gray100: '#595959',
    gray90: '#8B8B8B',
    gray80: '#979797',
    gray70: '#A3A3A3',
    gray60: '#AFAFAF',
    gray50: '#BBBBBB',
    gray40: '#C2C2C2',
    gray30: '#D3D3D3',
    gray20: '#DFDFDF',
    gray10: '#EBEBEB',
    white: '#FFFFFF'
  }
}
