import * as React from 'react';
import { logoProps } from './_types';
import IconBase from './IconBase';

const Avatar = ( props:logoProps ) => {
  return (
    <IconBase {...props}>
      <mask id="maskavatar" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
        <path d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM10 26.38V24.38C9.9031 22.621 11.2419 21.1126 13 21H19C20.7622 21.1125 22.1026 22.6272 22 24.39V26.39C18.2928 28.5544 13.7072 28.5544 10 26.39V26.38ZM24 24.92V24.31C24.0621 21.4719 21.8367 19.1085 19 19H13C10.1633 19.1085 7.93788 21.4719 8 24.31V24.93C4.29156 21.6131 3.0146 16.3517 4.78993 11.7039C6.56526 7.05599 11.0246 3.98573 16 3.98573C20.9754 3.98573 25.4347 7.05599 27.2101 11.7039C28.9854 16.3517 27.7084 21.6131 24 24.93V24.92ZM16 7C13.2386 7 11 9.23858 11 12C11 14.7614 13.2386 17 16 17C18.7614 17 21 14.7614 21 12C21 10.6739 20.4732 9.40215 19.5355 8.46447C18.5979 7.52678 17.3261 7 16 7ZM16 15C14.3431 15 13 13.6569 13 12C13 10.3431 14.3431 9 16 9C17.6569 9 19 10.3431 19 12C19 12.7956 18.6839 13.5587 18.1213 14.1213C17.5587 14.6839 16.7956 15 16 15Z" fill="white"/>
      </mask>
      <g mask="url(#maskavatar)">
        <path d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM10 26.38V24.38C9.9031 22.621 11.2419 21.1126 13 21H19C20.7622 21.1125 22.1026 22.6272 22 24.39V26.39C18.2928 28.5544 13.7072 28.5544 10 26.39V26.38ZM24 24.92V24.31C24.0621 21.4719 21.8367 19.1085 19 19H13C10.1633 19.1085 7.93788 21.4719 8 24.31V24.93C4.29156 21.6131 3.0146 16.3517 4.78993 11.7039C6.56526 7.05599 11.0246 3.98573 16 3.98573C20.9754 3.98573 25.4347 7.05599 27.2101 11.7039C28.9854 16.3517 27.7084 21.6131 24 24.93V24.92ZM16 7C13.2386 7 11 9.23858 11 12C11 14.7614 13.2386 17 16 17C18.7614 17 21 14.7614 21 12C21 10.6739 20.4732 9.40215 19.5355 8.46447C18.5979 7.52678 17.3261 7 16 7ZM16 15C14.3431 15 13 13.6569 13 12C13 10.3431 14.3431 9 16 9C17.6569 9 19 10.3431 19 12C19 12.7956 18.6839 13.5587 18.1213 14.1213C17.5587 14.6839 16.7956 15 16 15Z" />
      </g>
    </IconBase>
  )
}

export default Avatar;