import React from 'react'
import styled from 'styled-components'
import { Surface } from '../common/surface'
import { Text } from '../common/text'
import { Grid } from '../common/Layout/Grid'

interface BaseModalProps {
  size: 'sm' | 'md' | 'lg',
  isOpen: boolean,
  title: string,
  content: React.ReactElement,
  actions: React.ReactElement
  className?: string
}

interface ModalSize {
  sm: number,
  md: number,
  lg: number
}
const getModalSize = (size: string): ModalSize => {
  if (size === 'sm') {
    return {
      sm: 12,
      md: 4,
      lg: 4
    }
  } else if (size === 'md') {
    return {
      sm: 12,
      md: 6,
      lg: 6
    }
  } else {
    return {
      sm: 12,
      md: 8,
      lg: 8
    }
  }
}

const BaseModal = (props: BaseModalProps) => <div className={props.className}>
  <Grid container center>
    <Grid item xs={12} sm={getModalSize(props.size).sm} md={getModalSize(props.size).md} lg={getModalSize(props.size).lg}>
      <Surface shadow={1} radius='medium'>
        <Text type='h4' className='modal-title'>{props.title}</Text>
        <Grid container className='modal-content'>
          <Grid item xs={12} >
            {props.content}
          </Grid>
        </Grid>

        <Grid container >
          <Grid item xs={12} className='modal-actions'>
            {props.actions}
          </Grid>
        </Grid>
      </Surface>
    </Grid>
  </Grid>
</div>

export const Modal = styled(BaseModal)`
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

    .modal-title {
        text-align: left;
        margin-bottom: 0.7rem;
    }

    .modal-content {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;
    }

    .modal-actions > button {
      margin-right: 1rem;
    }
    .modal-actions > button:last-child {
      margin-right: 0;
    }
`
