import * as React from 'react'
import { logoProps } from './_types'
import IconBase from './IconBase'

const ChevronLeft = (props: logoProps) => {
  return (
    <IconBase {...props}>
      <mask id='maskcvl' mask-type='alpha' maskUnits='userSpaceOnUse' x='10' y='6' width='12' height='20'>
        <path d='M10 16L20 6L21.414 7.414L12.828 16L21.414 24.586L20 26L10 16Z' fill='white'/>
      </mask>
      <g mask='url(#maskcvl)'>
        <path d='M10 16L20 6L21.414 7.414L12.828 16L21.414 24.586L20 26L10 16Z'/>
      </g>
    </IconBase>
  )
}

export default ChevronLeft
