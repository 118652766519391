import * as React from 'react'
import { RadioGroupProps, RadioButtonProps } from './types'
import styled from 'styled-components'

const BaseRadioGroup = (props: RadioGroupProps) => {
  const childrenWithProps = React.Children.map(props.children, (child: any) => {
    return React.cloneElement(child as unknown as React.ReactElement<RadioButtonProps>, {
      name: props.name,
      onChange: props.onChange,
      checked: props.value === child.props.value
    })
  })

  return (
    <div className={props.className}>
      {props.label && <p>{props.label}</p>}
      {
        childrenWithProps
      }
      {props.error && props.errorText && <span className='error'>{props.errorText}</span>}
    </div>
  )
}

export const RadioGroup = styled(BaseRadioGroup)`
  label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: inherit;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid #979797;
    box-sizing: border-box;
  }

  span.error {
    font-size: 0.75rem;
    color: #f36666;
    margin-bottom: 10px;
    display: block;
  }

  label:hover input ~ span.checkmark {
    background-color: #DFDFDF;
  }

  input:checked ~ span.checkmark {
    background-color: transparent;
    border-color: ${props => !props.error ? '#005DBA;' : '#f36666'};
    box-sizing: border-box;
  }

  input:disabled ~ span.checkmark, label:hover input:disabled ~ span.checkmark {
    background-color: #979797;
  }

  span.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props => !props.error ? '#005DBA;' : '#f36666'};
    box-sizing: border-box;
  }

  input:checked ~ span.checkmark:after {
    display: block;
    box-sizing: border-box;
  }
`
