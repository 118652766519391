import * as React from 'react'
import styled from 'styled-components'
import { TextProps } from './types'
import { colors } from '../colors'

const Heading1 = styled.h1`
  font-size: 2.5rem;
  line-height: 1.1em;
  margin: 0.5rem 0 1.5rem 0;
  color: ${colors.grayscale.gray100};
`

const Heading2 = styled.h2`
  font-size: 2rem;
  line-height: 1.1em;
  margin: 0.5rem 0 1.5rem 0;
  color: ${colors.grayscale.gray100};
`

const Heading3 = styled.h3`
  font-size: 1.75rem;
  line-height: 1.1em;
  margin: 0.5rem 0 1.5rem 0;
  color: ${colors.grayscale.gray100};
`

const Heading4 = styled.h4`
  font-size: 1.5rem;
  line-height: 1.1em;
  margin: 0.5rem 0 1.5rem 0;
  color: ${colors.grayscale.gray100};
`

const Heading5 = styled.h5`
  font-size: 1.25rem;
  line-height: 1.1em;
  margin: 0.5rem 0 1.5rem 0;
  color: ${colors.grayscale.gray100};
`

const Heading6 = styled.h6`
  font-size: 1rem;
  line-height: 1.1em;
  margin: 0.5rem 0 1.5rem 0;
  color: ${colors.grayscale.gray100};
`

const Parragraph = styled.p`
  font-size: 1rem;
  line-height: 1.3rem;
  margin: 0.5rem 0 0;
  color: ${colors.grayscale.gray100};
`

const ParragraphSmall = styled.p`
  font-size: 0.875rem;
  margin: 0;
  margin: 0.5rem 0 0 0;
  color: ${colors.grayscale.gray100};
`

const ParragraphSemiSmall = styled.p`
  font-size: 0.75rem;
  margin: 0;
  margin: 0.5rem 0 0 0;
  color: ${colors.grayscale.gray100};
`

export const Text = (props: TextProps) => {
  if (props.type === 'h1') {
    return <Heading1 className={props.className}>{props.children}</Heading1>
  }

  if (props.type === 'h2') {
    return <Heading2 className={props.className}>{props.children}</Heading2>
  }

  if (props.type === 'h3') {
    return <Heading3 className={props.className}>{props.children}</Heading3>
  }

  if (props.type === 'h4') {
    return <Heading4 className={props.className}>{props.children}</Heading4>
  }

  if (props.type === 'h5') {
    return <Heading5 className={props.className}>{props.children}</Heading5>
  }

  if (props.type === 'h6') {
    return <Heading6 className={props.className}> {props.children}</Heading6>
  }

  if (props.type === 'p') {
    return <Parragraph className={props.className}>{props.children}</Parragraph>
  }

  if (props.type === 'small') {
    return <ParragraphSmall className={props.className}>{props.children}</ParragraphSmall>
  }

  if (props.type === 'semismall') {
    return <ParragraphSemiSmall className={props.className}>{props.children}</ParragraphSemiSmall>
  }

  return <Parragraph className={props.className}>{props.children}</Parragraph>
}

export const PrimaryText = styled(Text)`
  color: ${colors.primary.base};
`
export const SecondaryText = styled(Text)`
  color: ${colors.secondary.base};
`
export const TertiaryText = styled(Text)`
  color: ${colors.tertiary.base};
`
export const WarningText = styled(Text)`
  color: ${colors.warning.base};
`
export const WarningAltText = styled(Text)`
  color: ${colors.altw.base};
`
export const ErrorText = styled(Text)`
  color: ${colors.error.base};
`
export const WhiteText = styled(Text)`
  color: ${colors.grayscale.white};
`
export const BlackText = styled(Text)`
  color: ${colors.grayscale.black};
`
