import { colors } from './colors'

const getColor = (colorType: string | undefined) => {
  let color = colors.grayscale.black

  if (colorType === 'primary') {
    color = colors.primary.base
  } else if (colorType === 'secondary') {
    color = colors.secondary.base
  } else if (colorType === 'tertiary') {
    color = colors.tertiary.base
  } else if (colorType === 'warning') {
    color = colors.warning.base
  } else if (colorType === 'altw') {
    color = colors.altw.base
  } else if (colorType === 'error') {
    color = colors.error.base
  } else if (colorType === 'white') {
    color = colors.grayscale.white
  }

  return color
}

export default getColor
