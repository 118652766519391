import * as React from 'react'
import { logoProps } from './_types'
import IconBase from './IconBase'

const LastPage = (props: logoProps) => {
  return (
    <IconBase {...props}>
      <mask id='maslast' mask-type='alpha' maskUnits='userSpaceOnUse' x='6' y='4' width='18' height='24'>
        <path d='M18 16L8 26L6.586 24.586L15.172 16L6.586 7.414L8 6L18 16ZM22 4H24V28H22V4Z' fill='white'/>
      </mask>
      <g mask='url(#maslast)'>
        <path d='M18 16L8 26L6.586 24.586L15.172 16L6.586 7.414L8 6L18 16ZM22 4H24V28H22V4Z'/>
      </g>
    </IconBase>
  )
}

export default LastPage
